<template>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-12">
      <card-item :title="$t('interface.navigation_frontpage')" icon="icon-interface-font">
        <div class="grid-x grid-margin-x">
          <div class="cell medium-12">
            <ab-select :label="$t('interface.navigation_page_name')" :options="pages" v-model="settings.navigation"></ab-select>
          </div>
        </div>
        <div class="spacer--20"></div>
        <p>{{ $t('interface.navigation_explanation') }}</p>
      </card-item>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'InterfaceSettingsNavigation',
  props: ['content'],
  data () {
    return {
      pages: [
        {
          title: 'Welcome',
          value: '/welcome'
        },
        {
          title: 'Dashboard',
          value: '/dashboard'
        }
      ]
    }
  },
  computed: {
    settings: {
      get () {
        return this.content
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    backend.get(`${process.env.VUE_APP_URL}/pages/all`).then(response => {
      const pageList = this.pageList(response.data.pages)
      pageList.forEach(pageListItem => {
        this.pages.push(pageListItem)
      })
    })
  },
  methods: {
    pageList (pages, depth) {
      if (depth === undefined) {
        depth = 0
      }
      const pageList = []
      pages.forEach(page => {
        pageList.push({
          title: '- '.repeat(depth) + page.name,
          value: page.url
        })
        if (page.children) {
          const childPageList = this.pageList(page.children, depth + 1)
          childPageList.forEach(childPageListItem => {
            pageList.push(childPageListItem)
          })
        }
      })
      return pageList
    }
  }
}
</script>

<style lang="scss" scoped></style>
